define('client/models/report-items/article-top', ['exports', 'client/models/report-item'], function (exports, _reportItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportItem.default.extend({
    title: (0, _reportItem.aliasWithDefault)("title", ""),
    showFacebook: (0, _reportItem.aliasWithDefault)("showFacebook", true),
    showTwitter: (0, _reportItem.aliasWithDefault)("showTwitter", true),
    showGoogle: (0, _reportItem.aliasWithDefault)("showGoogle", function () {
      return this.gplusAvailable;
    }),
    showLinkedIn: (0, _reportItem.aliasWithDefault)("showLinkedIn", function () {
      return this.linkedinAvailable;
    }),
    showPinterest: (0, _reportItem.aliasWithDefault)("showPinterest", function () {
      return this.pinterestAvailable;
    }),
    showReadership: (0, _reportItem.aliasWithDefault)("showReadership", false),
    showVisitors: (0, _reportItem.aliasWithDefault)("showVisitors", false),
    showRevenue: (0, _reportItem.aliasWithDefault)("showRevenue", false),
    showInteraction: (0, _reportItem.aliasWithDefault)("showInteraction", false),
    useInteractionCount: (0, _reportItem.aliasWithDefault)("useInteractionCount", true),
    showFormulaicRevenue: (0, _reportItem.aliasWithDefault)("showFormulaicRevenue", false),
    _showReach: (0, _reportItem.aliasWithDefault)("showReach", false),
    showReach: Ember.computed('_showReach', 'profile.showReach', {
      get: function get() {
        return this.get('_showReach') && this.get('profile.showReach');
      },
      set: function set(k, v) {
        this.set('_showReach', v);
        return v && this.get('profile.showReach');
      }
    }),
    allMetrics: (0, _reportItem.aliasWithDefault)("allMetrics", true),
    querySyndicates: (0, _reportItem.aliasWithDefault)("querySyndicates", true),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('query', this.queryByName("article", { uniqueArticleIds: true }));
      this._super.apply(this, arguments);
    },

    allFilters: Ember.computed.alias('selectedFilterSet.articleListAdapter.filters'),

    info: Ember.computed.debounce('allFilters.[]', "profile_id", "campaign_id", "toDate", "fromDate", "searchTerm", "sort", "useInteractionCount", "perPage", "offset", "tagFilters.[]", 'languages.[]', "locationFilterList.[]", "locationFilters.[]", "uninfluential", "influential", 'syndicated', 'followOn', 'relevance', 'hideLongTail', 'companyWebsiteLink', 'articleLinksContain', "whitelistTagIds.[]", "profile.locations.[]", "profile.tags.[]", "allMetrics", "querySyndicates", 'selectedFilterSet.id', 'selectedFilterSet.isLoaded', function () {
      // check if that list is still on the report.
      if (this.get("page") && this.get('selectedFilterSet.isLoaded')) {
        return this.get('query').load();
      }
    }, 200),

    getCampaignAndProfileId: function getCampaignAndProfileId() {
      // for consistancy with the charts
    },
    setProfileNameOverride: function setProfileNameOverride() {
      // for consistancy with the charts
    }
  });
});