define('client/components/plan/campaigns/campaign-duration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['campaign-duration', 'campaign-creation-navigation'],

    prompt: 'When will this campaign be active?',
    onNext: function onNext() {},
    onPrevious: function onPrevious() {},
    onSelectedCampaignDuration: function onSelectedCampaignDuration() {},


    selectedDuration: Ember.computed('durationOptions', {
      get: function get(k) {
        return this.get('durationOptions').get('firstObject').get('value');
      },
      set: function set(k, option) {
        if (option) {
          if (typeof option === 'string') {
            return option;
          } else {
            // Show previously selected when user navigates between steps
            var durationOption = this.get('durationOptions').findBy('value', option.value);
            durationOption.set('startDate', option.startDate);
            durationOption.set('endDate', option.endDate);
            return option.value;
          }
        } else {
          // Default
          return this.get('durationOptions').get('firstObject').get('value');
        }
      }
    }),

    durationOptions: Ember.computed('profileStartDate', function () {
      var profileStartDate = moment(this.get('profileStartDate')).toDate();
      var durationOptions = Ember.A([Ember.Object.create({ value: 'all', startDate: profileStartDate, endDate: null }), Ember.Object.create({ value: 'ongoing', startDate: new Date(), endDate: null }), Ember.Object.create({ value: 'fromTo', startDate: new Date(), endDate: new Date() })]);

      return durationOptions;
    }),

    isDateRangeError: Ember.computed('selectedDuration', 'durationOptions.@each.{startDate,endDate}', function () {
      var duration = this.get('selectedDuration');
      var isDateRangeError = false;
      if (duration === 'fromTo') {
        var startDate = this.get('durationOptions').findBy('value', duration).startDate;
        var endDate = this.get('durationOptions').findBy('value', duration).endDate;
        isDateRangeError = moment(endDate).isBefore(moment(startDate), 'day');
      }
      return isDateRangeError;
    }),

    setDuration: function setDuration() {
      var option = this.get('selectedDuration');
      var durationOption = this.get('durationOptions').findBy('value', option);
      this.onSelectedCampaignDuration(durationOption);
    },


    actions: {
      onSelectedStartDate: function onSelectedStartDate(date) {
        var duration = this.get('selectedDuration');
        var option = this.get('durationOptions').findBy('value', duration);
        option.set('startDate', date);
      },
      onSelectedEndDate: function onSelectedEndDate(date) {
        var duration = this.get('selectedDuration');
        var option = this.get('durationOptions').findBy('value', duration);
        option.set('endDate', date);
      },
      onNext: function onNext() {
        this.setDuration();
        this.onNext();
      },
      onPrevious: function onPrevious() {
        this.setDuration();
        this.onPrevious();
      }
    }
  });
});