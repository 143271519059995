define('client/models/campaign', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var Campaign = _emberData.default.Model.extend(Validations, {

    isCampaign: true,

    name: _emberData.default.attr("string"),
    startDate: _emberData.default.attr("isodate"),
    endDate: _emberData.default.attr("isodate"),

    filterSets: _emberData.default.hasMany('filter-set', { inverse: null }),

    defaultFilterSet: _emberData.default.belongsTo('filter-set', { inverse: null }),

    profile_id: _emberData.default.attr("string"),
    _profile_id: Ember.computed.alias("profile_id"),
    campaign_id: Ember.computed.alias("id"),
    tag_id: _emberData.default.attr("string"),

    interaction_metrics: _emberData.default.attr('object'),

    campaign_analytics_event_relationships: _emberData.default.hasMany("campaign_analytics_event_relationship", { async: true }),
    campaign_analytics_goal_relationships: _emberData.default.hasMany("campaign_analytics_goal_relationship", { async: true }),
    campaign_keywords: _emberData.default.hasMany("campaign_keyword", { async: true }),

    profile: _emberData.default.belongsTo('profile', { inverse: 'campaigns' }),

    savedSearches: Ember.computed.alias("profile.savedSearches"),
    soundbytes: Ember.computed.alias("profile.soundbytes"),

    hasAnalytics: Ember.computed.alias("profile.hasAnalytics"),
    competitorColors: Ember.computed.alias("profile.competitorColors"),
    showAuthorIntelligence: Ember.computed.alias("profile.showAuthorIntelligence"),
    hasBroadcast: Ember.computed.alias("profile.hasBroadcast"),
    hasPrint: Ember.computed.alias("profile.hasPrint"),
    hasRelevanceSlider: Ember.computed.alias("profile.hasRelevanceSlider"),

    // Alias many fields from profile
    company_name: Ember.computed.alias("name"),
    tiles: Ember.computed.alias("profile.tiles"),
    image_url: Ember.computed.alias("profile.image_url"),
    hasImageUrl: Ember.computed.alias("profile.hasImageUrl"),
    sorts: Ember.computed.alias("profile.sorts"),
    tags: Ember.computed.alias("profile.alphabeticalTags"),
    alphabeticalTags: Ember.computed.alias("tags"),
    whitelistTags: Ember.computed.alias("profile.whitelistTags"),
    competitorWhitelistTags: Ember.computed.alias("profile.competitorWhitelistTags"),
    allWhitelistTags: Ember.computed.alias("profile.allWhitelistTags"),
    parent: Ember.computed.alias("profile"),
    analytics_profile: Ember.computed.alias("profile.analytics_profile"),
    profileMessageBuckets: Ember.computed.alias("profile.profileMessageBuckets"),

    // alias the naming convention from the profile
    includeNeutralArticles: Ember.computed.alias("profile.includeNeutralArticles"),
    showRevenue: Ember.computed.alias("profile.showRevenue"),
    showReach: Ember.computed.and("profile.showReach", "profile.hasImpressions"),
    showReachSplit: Ember.computed.alias("profile.showReachSplit"),
    showFormulaicRevenue: Ember.computed.alias("profile.showFormulaicRevenue"),
    showInteraction: Ember.computed.alias("profile.showInteraction"),
    showCustomer: Ember.computed.alias("profile.showCustomer"),
    showStoryLevels: Ember.computed.alias("profile.showStoryLevels"),
    showReadership: Ember.computed.alias("profile.showReadership"),
    hasArticleSentiment: Ember.computed.alias("profile.hasArticleSentiment"),
    nameSentiment: Ember.computed.alias("profile.nameSentiment"),
    hasImpactScore: Ember.computed.alias("profile.hasImpactScore"),

    revenuePerCustomer: Ember.computed.alias("profile.revenuePerCustomer"),
    conversionRate: Ember.computed.alias("profile.conversionRate"),

    nameRevenue: Ember.computed.alias("profile.nameRevenue"),
    nameReach: Ember.computed.alias("profile.nameReach"),
    nameFormulaicRevenue: Ember.computed.alias("profile.nameFormulaicRevenue"),
    nameInteraction: Ember.computed.alias("profile.nameInteraction"),
    nameImpactScore: Ember.computed.alias("profile.nameImpactScore"),
    nameTotalInteraction: Ember.computed('profile.nameInteraction', function () {
      return 'Total ' + this.get('profile.nameInteraction');
    }),
    nameUseInteractionCount: Ember.computed('profile.nameInteraction', function () {
      return 'Use ' + this.get('profile.nameInteraction') + ' count instead of percentage';
    }),
    nameInteractionCount: Ember.computed('profile.nameInteraction', function () {
      return this.get('profile.nameInteraction') + ' Count';
    }),
    nameInteractionPercentage: Ember.computed('profile.nameInteraction', function () {
      return this.get('profile.nameInteraction') + ' Percentage';
    }),

    nameCustomer: Ember.computed.alias("profile.nameCustomer"),
    locations: Ember.computed.alias("profile.locations"),
    nameReadership: Ember.computed.alias("profile.nameReadership"),

    customFiscalYearEndDate: Ember.computed.alias("profile.customFiscalYearEndDate"),
    customFiscalYearOffset: Ember.computed.alias("profile.customFiscalYearOffset"),

    // Load dashboard info based on campaign_id
    _info: Ember.computed("id", function () {
      var profile = this.get("profile_id");
      return this.store.queryRecord("dashboard_info", { id: profile, campaign_id: this.get("id") });
    }),
    info: Ember.computed.alias("_info.firstObject"),

    // Mimic profile hierarchy
    parents: Ember.computed.alias("profile.parents"),
    children: [],
    profileTypes: [],

    // Remove selected goals from the list
    availableGoals: Ember.computed("analytics_profile.analytics_goals.[]", "campaign_analytics_goal_relationships.@each.analytics_goal", function () {
      var metrics = this.get("campaign_analytics_goal_relationships"),
          profileMetrics = this.get("analytics_profile.analytics_goals");

      return profileMetrics && profileMetrics.reject(function (m) {
        return !!metrics.findBy("analytics_goal.id", m.get("id"));
      });
    }),

    // Remove selected events from the list
    availableEvents: Ember.computed("analytics_profile.analytics_events.[]", "campaign_analytics_event_relationships.@each.analytics_event", function () {
      var metrics = this.get("campaign_analytics_event_relationships"),
          profileMetrics = this.get("analytics_profile.analytics_events");

      return profileMetrics && profileMetrics.reject(function (m) {
        return !!metrics.findBy("analytics_event.id", m.get("id"));
      });
    }),

    // Combine both goals and events
    availableMetrics: Ember.computed.union("availableGoals", "availableEvents"),

    // Combine goals and events and sort them by created time
    _metrics: Ember.computed.union("campaign_analytics_event_relationships", "campaign_analytics_goal_relationships"),
    metrics: Ember.computed.sort("_metrics", function (a, b) {
      var aDate = a && a.get("created_at") ? a.get("created_at") : new Date();
      var bDate = b && b.get("created_at") ? b.get("created_at") : new Date();
      return aDate.getTime() - bDate.getTime();
    })

  });
  Campaign.pathForType = "campaigns";

  exports.default = Campaign;
});