define('client/controllers/settings/added-articles/index', ['exports', 'client/mixins/data-table-sort', 'ember-parachute'], function (exports, _dataTableSort, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var articlesQP = new _emberParachute.default({
    sortUrl: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    sortPattern: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    sortDate: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    sortAddedDate: {
      defaultValue: "DESC",
      refresh: true,
      replace: true
    },
    sortEndDate: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    sortStartDate: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    sortName: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    sortProfile: {
      defaultValue: null,
      refresh: true,
      replace: true
    }
  });

  exports.default = Ember.Controller.extend(articlesQP.Mixin, _dataTableSort.default, Ember.Evented, {

    modal: Ember.inject.service(),
    profile: Ember.computed.alias('profileService.profile'),

    _sorts: ["Date", "StartDate", "EndDate", "Url", "Pattern", "Name", "Profile", "AddedDate"],

    queryParamsChanged: Ember.computed.or('queryParamsState.{type,sortUrl,sortPattern,sortDate,profile,sortAddedDate,sortEndDate,sortStartDate,sortName,perType,sortProfile}.changed'),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('queryParamsChanged');
    },
    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh,
          queryParams = _ref.queryParams;

      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        Ember.run.scheduleOnce("afterRender", this, "_paramsDidChange");
      }
    },


    links: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "added_link",
        store: this.get("store")
      });
      Ember.run.later(this, "_paramsDidChange");
      return findPagingController;
    }),

    newLink: Ember.computed('profile', function () {
      return this.store.createRecord('link', {
        profile_id: this.get('profile.id')
      });
    }),

    resources: Ember.computed.alias("links"),

    actions: {

      updateLinkTimestamp: function updateLinkTimestamp(link, newDate) {
        var content = link.get('content');
        if (content.get('timestamp') !== newDate) {
          content.set('timestamp', newDate);
          content.set("profile_id");
          content.save().then(function () {
            //TODO: fix this
            // Ember.run.debounce(this.get("controllers.application"), "resetLinksDateCount", 1000);
          });
        }
      },

      addLink: function addLink(newLink) {
        var _this = this;

        if (Ember.isEmpty(newLink.get('url'))) {
          this.get('modal').open('common/ui-confirm', {
            prompt: 'Not a valid URL',
            ok: 'OK',
            okType: "danger"
          });
          return;
        }

        this.set('isAddingArticle', true);
        newLink.save().then(function (res) {
          _this.notifyPropertyChange("links");
          _this.notifyPropertyChange("newLink");
          _this.get('modal').open('common/ui-confirm', {
            prompt: 'URL has been successfully added. You will receive an email when its ready to be viewed.',
            ok: 'OK',
            showCancel: false,
            okType: "info"
          });
        }).catch(function (failure) {
          var error_type = failure.errors[0].status;
          var error = failure.errors[0].detail;

          if (error_type === "401" || error_type === "500") {
            error = "We've encountered an error. Please contact your PR Engineer to resolve this!";
          }
          _this.get('modal').open('common/ui-confirm', {
            prompt: error,
            ok: 'OK',
            showCancel: false,
            okType: "danger"
          });
          _this.notifyPropertyChange("links");
          _this.notifyPropertyChange("newLink");
        }).finally(function () {
          _this.set('isAddingArticle', false);
        });
      },

      deleteLink: function deleteLink(link) {
        var _this2 = this;

        this.get('modal').open('common/ui-confirm', {
          question: "Are you sure?",
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          link.get("content").removeLink().then(function () {
            //TODO: fix this
            // this.get("controllers.application").resetLinksDateCount();
          });
          _this2.get("links.model").removeObject(link);
        });
      }
    }
  });
});